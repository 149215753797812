import React from "react";
import SEO from "@/components/seo/index.ru";
import * as styleConstants from "@/constants/style";
import cn from "classnames";
import Button from "@/components/kit/button";
import { useRoute } from "@/utils";
import AniLogo from "@/components/kit/ani-logo";
import * as css from "@/components/pages/404/404.modules.scss";

const MESSAGES = {
  title: {
    ru: "404: Не найдено",
    en: "404: Not found",
  },
  h1: {
    ru: "Страница не&nbsp;найдена",
    en: "Page is&nbsp;not found",
  },
  home: {
    ru: "Главная",
    en: "Home",
  },
  tickets: {
    ru: "Билеты",
    en: "Tickets",
  },
};

const NotFoundPage = ({ pageContext }) => {
  const { langKey } = pageContext;
  const route = useRoute();

  return (
    <>
      <SEO title={MESSAGES.title[langKey]} />
      <section
        className={cn(
          css.page,
          styleConstants.container,
          "flex flex-col justify-between pb-6 tablet:pb-7 desktop:pb-10 desktop1280:pb-11 desktop1440:pb-12",
          "desktop:pt-10 desktop1280:pt-12 desktop1440:pt-14"
        )}
      >
        <h1
          className={
            "tablet:w-2/3 mt-4 tablet:mt-6 desktop:mt-5 desktop1280:mt-7"
          }
          dangerouslySetInnerHTML={{ __html: MESSAGES.h1[langKey] }}
        />
        <div className={"flex justify-between relative"}>
          <div className={"flex w-full flex-col tablet:flex-row"}>
            <Button
              is={"a"}
              href={route("home")}
              className={"w-full tablet:w-7/24 desktop:w-1/4"}
            >
              {MESSAGES.home[langKey]}
            </Button>
            <div className={"hidden tablet:block w-1/24"} />
            <Button
              is={"a"}
              href={route("program")}
              className={"w-full tablet:w-7/24 desktop:w-1/4 mt-4 tablet:mt-0"}
              variant={"outlined"}
            >
              {MESSAGES.tickets[langKey]}
            </Button>
          </div>
          <div className={"absolute hidden tablet:block right-0 bottom-0"}>
            <AniLogo className={css.logoIcon} />
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFoundPage;
