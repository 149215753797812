import React from "react";
import SEO, { SeoProps } from "./seo";
import { graphql, useStaticQuery } from "gatsby";

const SeoRU: React.FC<Omit<SeoProps, "site">> = (props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site: contentfulSiteSettings(node_locale: { eq: "ru-RU" }) {
          ogImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          description {
            description
          }
          title
          node_locale
        }
      }
    `
  );

  return <SEO {...props} site={site} />;
};

export default SeoRU;
