import React from "react";
import cn from "classnames";
import { ButtonProps } from "./types";
import Loader from "@/components/kit/loader";
import * as css from "./button.modules.scss";

const defaultTag = "button";
const defaultVariant = "contained";

function Button<E extends React.ElementType = typeof defaultTag>({
  className,
  noHover = false,
  is,
  variant = defaultVariant,
  children,
  renderIcon,
  loading,
  ...rest
}: ButtonProps<E>) {
  const TagName = is || defaultTag;

  return (
    <TagName
      className={cn(
        css.root,
        {
          [css.rootHover]: !noHover,
          [css.containsIcon]: typeof renderIcon === "function",
        },
        "uppercase relative",
        `variant-${variant}`,
        className
      )}
      disabled={rest.disabled || loading}
      aria-disabled={rest.disabled || loading}
      {...rest}
    >
      <span>
        {children}

        {loading && (
          <span className={css.loaderContainer}>
            <Loader />
          </span>
        )}
        {typeof renderIcon === "function" && renderIcon()}
      </span>
    </TagName>
  );
}

export default Button;
