import React from "react";
import Helmet from "react-helmet";
import isEqual from "react-fast-compare";

type MetaItem = { name?: string; content: string; property?: string };
type OgImage = {
  file: {
    url: string;
    details: {
      image: {
        height: number;
        width: number;
      };
    };
  };
};

export type SeoProps = {
  title?: string;
  description?: string;
  lang?: string;
  meta?: MetaItem[];
  site: {
    title: string;
    description?: {
      description: string;
    };
    ogImage?: OgImage;
    node_locale: string;
  };
  ogImage?: OgImage;
};

const SEO: React.FC<SeoProps> = React.memo(
  ({ description, meta = [], title, site, ogImage }) => {
    const metaDescription = description || site.description?.description;
    const safeTitle = title
      ? title.replace(/[^а-яА-Яa-zA-Z0-9- .,:;'!~?&*%$#@^"()\[\]]/g, "")
      : null;

    let defaultMeta: MetaItem[] = [
      {
        name: "description",
        content: metaDescription,
      },
      {
        property: "og:title",
        content: safeTitle ? `${safeTitle} | ${site.title}` : site.title,
      },
      {
        property: "og:description",
        content: metaDescription,
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:locale",
        content: site.node_locale,
      },
      {
        property: "twitter:card",
        content: "summary_large_image",
      },
    ];

    if (ogImage || site.ogImage) {
      defaultMeta = [
        ...defaultMeta,
        {
          property: "og:image",
          content: `https:${(ogImage || site.ogImage)?.file.url}`,
        },
        {
          property: "og:image:width",
          content: `${(ogImage || site.ogImage)?.file.details.image.width}`,
        },
        {
          property: "og:image:height",
          content: `${(ogImage || site.ogImage)?.file.details.image.height}`,
        },
        {
          property: "vk:image",
          content: `https:${
            (ogImage || site.ogImage)?.file.url
          }?w=1200&q=65&fm=jpg`,
        },
      ];
    }

    return (
      <Helmet
        htmlAttributes={{
          lang: site.node_locale,
        }}
        title={safeTitle || site.title}
        titleTemplate={safeTitle && `%s | ${site.title}`}
        meta={defaultMeta.concat(meta)}
      />
    );
  },
  isEqual
);

export default SEO;
